import { createTheme } from "@material-ui/core/styles";

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: process.env.PRIMARY_COLOR || "#5161BA",
      dark:
        process.env.PRIMARY_DARK_COLOR ||
        process.env.PRIMARY_COLOR ||
        "#5161BA",
      light:
        process.env.PRIMARY_LIGHT_COLOR ||
        process.env.PRIMARY_COLOR ||
        "#5161BA",
      contrastText: "#fff",
    },
    secondary: {
      main: process.env.SECONDARY_COLOR || "#DCE4F1",
      contrastText: "#fff",
    },
    error: {
      main: "#EF1B43",
    },
    background: {
      default: "#fff",
    },
  },
  breakpoints: {
    values: {
      xs: 320,
      sm: 425,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    button: {
      textTransform: "none",
    },
    h1: {
      fontFamily: "'Playfair Display', serif",
      fontSize: 48,
      fontWeight: 700,
      "@media (max-width:425px)": {
        fontSize: 40,
      },
    },
    h2: {
      fontFamily: "'Playfair Display', serif",
      fontWeight: 700,
      fontSize: 32,
      "@media (max-width:425px)": {
        fontSize: 28,
      },
    },
    h3: {
      fontFamily: "'Playfair Display', serif",
      fontWeight: 700,
      fontSize: 20,
      "@media (max-width:425px)": {
        fontSize: 18,
      },
    },
    body1: {
      fontSize: 16,
      "@media (max-width:460px)": {
        fontSize: 14,
      },
    },
    body2: {
      fontSize: 14,
      "@media (max-width:460px)": {
        fontSize: 12,
      },
    },
  },
  overrides: {
    MuiAccordion: {
      root: {
        margin: "0px !important",
        "&:before": {
          backgroundColor: "none",
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: "0px 20px",
      },
    },
    MuiContainer: {
      root: {
        paddingLeft: "0px !important",
        paddingRight: "0px !important",
      },
    },
    MuiButton: {
      root: {
        "&$disabled": {
          color: "white !important",
          backgroundColor: "#929292 !important",
        },
      },
      containedPrimary: {
        "&:hover": {
          backgroundColor: process.env.PRIMARY_COLOR,
        },
      },
      label: {
        fontSize: 16,
        "@media (max-width:450px)": {
          fontSize: 14,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
      },
      notchedOutline: {
        borderColor: "#929292",
      },
      input: {
        padding: 16,
      },
      multiline: {
        padding: 16,
      },
      adornedStart: {
        paddingLeft: 16,
      },
    },
    MuiFormHelperText: {
      root: {
        "&$error": {
          color: "#EF1B43",
        },
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
});

export default theme;
