import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import dayjs from "dayjs";
import App from "next/app";
import Head from "next/head";
import Router from "next/router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import "pesanio-daily-price-date-picker/dist/main.css";
import React, { useContext, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { appWithTranslation } from "../i18n";
import withClearCache from "../src/clearCache";
import ErrorPage from "../src/components/ErrorPage";
import AppContextProvider, { AppContext } from "../src/context/AppContext";
import CartFoodContextProvider, {
  CartFoodContext,
} from "../src/context/CartFood";
import CartRoomContextProvider from "../src/context/CartRoom";
import CartSouvenirContextProvider, {
  CartSouvenirContext,
} from "../src/context/CartSouvenir";
import theme from "../src/theme";
import "../styles/globals.css";

var isBetween = require("dayjs/plugin/isBetween");
dayjs.extend(isBetween);

Router.events.on("routeChangeStart", (url) => {
  NProgress.start();
});
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

function Index(props) {
  const { t } = props;
  const { restoreCart } = useContext(CartFoodContext);
  const { restoreSouvenirCart } = useContext(CartSouvenirContext);
  const { errorResponse } = useContext(AppContext);
  const authToken = Router.query.authToken;

  useEffect(() => {
    const loginDate = localStorage.getItem("loginDate");
    const today = dayjs(new Date()).format("YYYY-MM-DD");
    if (loginDate && dayjs(today).diff(loginDate, "days") >= 2) {
      localStorage.clear();
    }

    restoreCart();
    restoreSouvenirCart();
  }, []);

  useEffect(() => {
    if (authToken) {
      localStorage.setItem("token", authToken as string);
      localStorage.setItem("loginDate", dayjs(new Date()).format("YYYY-MM-DD"));
    }
  }, [Router.query]);

  // const getGuestInfo = async () => {
  //   try {
  //     const response = await getGuestAuthInfo();
  //     localStorage.setItem("data_login", JSON.stringify(response.data.guest));
  //   } catch (error) {}
  // };

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   if (token) {
  //     getGuestInfo();
  //   }
  // }, []);

  return (
    <div>
      <ErrorPage
        error={
          errorResponse?.status_code === 500 ||
          errorResponse?.status_code === 503
            ? errorResponse?.status_code
            : 0
        }
        errorMessage={errorResponse?.message}
        errorTitle={errorResponse?.title}
        t={t}
      >
        {props.children}{" "}
      </ErrorPage>
    </div>
  );
}

const ClearCacheComponent = withClearCache(MainApp);

function MyApp(props) {
  return <ClearCacheComponent {...props} t />;
}

function MainApp({ Component, pageProps }) {
  return (
    <AppContextProvider>
      <CartFoodContextProvider>
        <CartRoomContextProvider>
          <CartSouvenirContextProvider>
            <ThemeProvider theme={theme}>
              <Head>
                <title>{process.env.TENANT_NAME || "Pesan.io"}</title>
              </Head>
              <CssBaseline />
              <Index>
                <Toaster />
                <Component {...pageProps} />
              </Index>
            </ThemeProvider>
          </CartSouvenirContextProvider>
        </CartRoomContextProvider>
      </CartFoodContextProvider>
    </AppContextProvider>
  );
}

MyApp.getInitialProps = async (appContext) => ({
  ...(await App.getInitialProps(appContext)),
});

export default appWithTranslation(MyApp);
